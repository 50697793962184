export interface Modals {
  selectedProductId?: number;
  selectedOfferId?: number;
}

const DEF_VALUE: Modals = {
  selectedOfferId: undefined,
  selectedProductId: undefined,
};

const createModalsStore = () => ({
  ...DEF_VALUE,
});

export default createModalsStore;
